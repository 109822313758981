import { render, staticRenderFns } from "./TableTenancies.vue?vue&type=template&id=f19c3e5c"
import script from "./TableTenancies.vue?vue&type=script&setup=true&lang=ts"
export * from "./TableTenancies.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TableTenancies.vue?vue&type=style&index=0&id=f19c3e5c&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserBadge: require('/app/components/UserBadge/UserBadge.vue').default,UserBadgeRow: require('/app/components/UserBadge/UserBadgeRow.vue').default,DataTable: require('/app/components/DataTable/index.vue').default})
