// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_DKMGA{background-color:var(--segmented-bar-border-color);border-radius:var(--border-radius-medium);display:flex;height:100%;width:100%}.container_DKMGA.height-small_WvINH{height:.5rem}.container_DKMGA.height-input_7vX0a,.container_DKMGA.height-small_WvINH{-webkit-clip-path:inset(0 0 0 0 round var(--border-radius-medium));clip-path:inset(0 0 0 0 round var(--border-radius-medium))}.container_DKMGA.height-input_7vX0a{height:2.5rem}.segment_Hh3gy{display:flex;height:100%;width:16.666666%}.segment_Hh3gy:first-child{border-bottom-left-radius:var(--border-radius-medium);border-top-left-radius:var(--border-radius-medium)}.segment_Hh3gy:last-child{border-bottom-right-radius:var(--border-radius-medium);border-top-right-radius:var(--border-radius-medium)}.segment_Hh3gy:not(:last-child){border-right:1px dotted var(--color-white)}.segment_Hh3gy:not(.inactive_wef2X){background-color:var(--segmented-bar-background-color)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_DKMGA",
	"height-small": "height-small_WvINH",
	"height-input": "height-input_7vX0a",
	"segment": "segment_Hh3gy",
	"inactive": "inactive_wef2X"
};
module.exports = ___CSS_LOADER_EXPORT___;
